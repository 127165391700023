import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Utils
import { ImageOrientation } from "../utils/image-orientation";

// Context
import { PageTypeConsumer } from "../components/context/page-type";

// Components
import { FilteredStills } from "../components/filters/filtered-stills";

const Container = styled.div`
  display: grid;
  grid-template-columns: 150px 1fr 150px;
  grid-column-gap: 90px;

  width: 100%;

  padding: 190px 40px 0 40px;

  @media (max-width: 1400px) {
    grid-column-gap: 60px;
  }

  @media (max-width: 1200px) {
    padding: 190px 30px 0 30px;
    grid-column-gap: 40px;
  }

  @media (max-width: 768px) {
    padding: 110px 20px 0 20px;
  }

  @media (max-width: 1000px) {
    display: block;
    grid-template-columns: unset;
    grid-column-gap: unset;
  }
`;

const Photographer = styled.div`
  & .portrait-image-container {
    padding: 0 50px;
  }

  & img.portrait {
    max-height: 600px;
    object-fit: contain;
  }

  & .details {
    text-align: center;

    & h2,
    & p {
      margin: 20px 0 0 0;

      font-size: 14px;
      line-height: 130%;
      letter-spacing: 0.56px;
    }

    /* margin-bottom: 100px;

    @media (max-width: 1200px) {
      margin-bottom: 30px;
    }

    @media (max-width: 900px) {
      margin-bottom: 0;
    } */
  }

  & a.image-wrapper {
    display: block;
    overflow: hidden;
  }

  & img,
  & video {
    transition: 300ms transform ease;
  }

  &:hover {
    & img,
    & video {
      transform: scale(1.05);
    }
  }
`;

const Biography = styled.div`
  padding: 60px 20px 110px 20px;
  margin: 0 auto;

  max-width: 616px;

  & p {
    font-family: "Caslon Doric Web", "Helvetica", "Lucida Grande", sans-serif;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 1.4px;
    text-align: left;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const MasonryContainer = styled.div`
  position: relative;
  width: 100%;

  .my-masonry-grid {
    display: flex;
    margin-left: -90px;
    width: auto;

    @media (max-width: 1000px) {
      margin-left: -60px;
    }
  }
  .my-masonry-grid_column {
    padding-left: 90px;
    background-clip: padding-box;

    @media (max-width: 1000px) {
      padding-left: 60px;
    }
  }
`;

const StillsContent = ({ data, setPageType }) => {
  useEffect(() => {
    setPageType("photography");
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const Macy = require("macy");

      const macyInstance = Macy({
        container: `#masonry`,
        columns: 2,
        margin: 90,
        breakAt: {
          1400: 2,
          767: 1,
        },
      });
    }
  }, []);

  const content = data.prismicPhotographers.data.projects.map(
    (content, index) => (
      <Photographer
        key={`single_photography_project_${content.project.document.id}_${index}`}
      >
        {/* <LazyLoad height={700} offset={200}> */}
        <Link
          to={content.project.document.url}
          state={{ from: `/photography`, section: "photography" }}
          className="image-wrapper"
        >
          {content.project.document.data.vimeo_mp4_file.url !== "" ? (
            <video
              src={content.project.document.data.vimeo_mp4_file.url}
              muted
              loop
              autoPlay
              playsInline
              preload="metadata"
            />
          ) : (
            <>
              {content.project.document.data.image_thumbnail.fluid !== null && (
                <div
                  className={`${ImageOrientation(
                    content.project.document.data.image_thumbnail
                  )}-image-container`}
                >
                  <img
                    className={ImageOrientation(
                      content.project.document.data.image_thumbnail
                    )}
                    srcSet={
                      content.project.document.data.image_thumbnail.fluid
                        .srcSetWebp
                    }
                    src={
                      content.project.document.data.image_thumbnail.fluid
                        .srcWebp
                    }
                    alt={content.project.document.data.image_thumbnail.alt}
                    // loading={index < 5 ? "eager" : "lazy"}
                  />
                </div>
              )}
            </>
          )}
        </Link>
        <div className="details">
          <Link
            to={content.project.document.url}
            state={{ from: `/photography`, section: "photography" }}
          >
            <h2 className="caslon-doric">
              {content.project.document.data.photographer.document !== null && (
                <>
                  <Link
                    to={content.project.document.data.photographer.document.url}
                    state={{ from: `/photography`, section: "photography" }}
                  >
                    {
                      content.project.document.data.photographer.document.data
                        .name.text
                    }
                  </Link>

                  <span className="spacer"> ・ </span>
                </>
              )}

              {content.project.document.data.title.text}
            </h2>
          </Link>
        </div>
        {/* </LazyLoad> */}
      </Photographer>
    )
  );

  return (
    <>
      <Helmet
        title={`Photography – Park Village`}
        meta={[
          {
            name: "og:title",
            content: `Photography – Park Village`,
          },
          {
            name: "twitter:title",
            content: `Photography – Park Village`,
          },
        ]}
      />
      <Container>
        <FilteredStills />

        <div className="masonry-outer-container">
          {/* <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          > */}
          <MasonryContainer id="masonry">{content}</MasonryContainer>
          {/* </Masonry> */}
        </div>

        {data.prismicPhotographers.data.text.text !== "" && (
          <Biography id="biography">
            <div
              dangerouslySetInnerHTML={{
                __html: data.prismicPhotographers.data.text.html,
              }}
            />
          </Biography>
        )}
      </Container>
    </>
  );
};

const Stills = ({ data, location }) => (
  <PageTypeConsumer>
    {({ setPageType }) => (
      <StillsContent
        setPageType={setPageType}
        data={data}
        location={location}
      />
    )}
  </PageTypeConsumer>
);

export default withPreview(Stills);

export const query = graphql`
  {
    prismicPhotographers {
      data {
        text {
          html
          text
        }
        projects {
          project {
            document {
              ... on PrismicPhotographyProject {
                id
                url
                data {
                  title {
                    text
                  }
                  photographer {
                    document {
                      ... on PrismicPhotographer {
                        id
                        url
                        data {
                          name {
                            text
                          }
                        }
                      }
                    }
                    url
                  }
                  image_thumbnail {
                    alt
                    fluid(
                      imgixParams: { auto: "format" }
                      srcSetBreakpoints: [200, 340, 520, 800]
                    ) {
                      srcWebp
                      srcSetWebp
                      aspectRatio
                    }
                    dimensions {
                      width
                      height
                    }
                  }
                  vimeo_mp4_file {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
